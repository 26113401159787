import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
  ISiteTextPreset,
} from '@wix/yoshi-flow-editor/tpa-settings';

type IStylesParams = {
  topSectionColor: StyleParamType.Color;
  bottomSectionColor: StyleParamType.Color;
  sectionsGap: StyleParamType.Number;
  pageTitleFont: StyleParamType.Font;
  pageTitleColor: StyleParamType.Color;
  pageSubtitleFont: StyleParamType.Font;
  pageSubtitleColor: StyleParamType.Color;
  primaryTextFont: StyleParamType.Font;
  primaryTextColor: StyleParamType.Color;
  secondaryTextFont: StyleParamType.Font;
  secondaryTextColor: StyleParamType.Color;
  actionColor: StyleParamType.Color;
  disabledColor: StyleParamType.Color;
  dateTextFont: StyleParamType.Font;
  dateTextColor: StyleParamType.Color;
  dateSelectedTextColor: StyleParamType.Color;
  dateBorderRadius: StyleParamType.Number;
  timeSelectionTextColor: StyleParamType.Color;
  timeSelectionTextFont: StyleParamType.Font;
  timeSelectionBorderColor: StyleParamType.Color;
  timeSelectionBorderWidth: StyleParamType.Number;
  timeSelectionBorderRadius: StyleParamType.Number;
  timeSelectionItemGap: StyleParamType.Number;
  bookButtonTextColor: StyleParamType.Color;
  bookButtonBGColor: StyleParamType.Color;
  bookButtonBorderColor: StyleParamType.Color;
  bookButtonHoverBorderColor: StyleParamType.Color;
  bookButtonHoverTextColor: StyleParamType.Color;
  bookButtonHoverBGColor: StyleParamType.Color;
  bookButtonBorderWidth: StyleParamType.Number;
  bookButtonHoverBorderWidth: StyleParamType.Number;
  bookButtonTextFont: StyleParamType.Font;
  bookButtonBGColorOpacity: StyleParamType.Number;
  bookButtonHoverBGColorOpacity: StyleParamType.Number;
  bookButtonBorderColorOpacity: StyleParamType.Number;
  bookButtonHoverBorderColorOpacity: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  topSectionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bottomSectionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  sectionsGap: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 44 : 60),
  },
  pageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  pageTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 32 }),
  },
  pageSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  pageSubtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 20 }),
  },
  primaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  primaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
      isMobile,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
  secondaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  secondaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: 14,
      })({ textPresets }),
  },
  actionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  disabledColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  dateTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  dateSelectedTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  dateTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
      isMobile,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
  dateBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 50,
  },
  timeSelectionTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
      isMobile,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
  timeSelectionTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  timeSelectionBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  timeSelectionBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  timeSelectionBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  timeSelectionItemGap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
  bookButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bookButtonBGColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bookButtonHoverBGColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bookButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bookButtonHoverBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  bookButtonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  bookButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  bookButtonHoverBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  bookButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: ({
      textPresets,
      isMobile,
    }: {
      textPresets: ISiteTextPreset;
      isMobile: boolean;
    }) =>
      wixFontParam('Body-M', {
        size: isMobile ? 14 : 16,
      })({ textPresets }),
  },
  bookButtonBGColorOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  bookButtonHoverBGColorOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 70,
  },
  bookButtonBorderColorOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 100,
  },
  bookButtonHoverBorderColorOpacity: {
    type: StyleParamType.Number,
    getDefaultValue: () => 70,
  },
});
