import React from 'react';
import { TimezoneSelectionViewModel } from '../../../ViewModel/timezoneSelectionViewModel/timezoneSelectionViewModel';
import { useCalendarActions } from '../../../Hooks/useCalendarActions';
import { TimezoneSelectionDataHooks } from './TimezoneSelectionDataHooks';
import { classes, st } from './TimezoneSelection.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { FloatingDropdown } from 'wix-ui-tpa/FloatingDropdown';
import { FloatingDropdownOptionProps } from 'wix-ui-tpa/dist/src/components/FloatingDropdown/FloatingDropdownOption';
import { Text } from 'wix-ui-tpa/Text';

export type TimezoneSelectionProps = {
  viewModel: TimezoneSelectionViewModel;
};

const TimezoneSelection: React.FC<TimezoneSelectionProps> = ({ viewModel }) => {
  const { onTimezoneSelected } = useCalendarActions();
  const { isMobile } = useEnvironment();
  const { selectableTimezones, selectedTimezone, timezoneLabel } = viewModel;

  const onChange = (selectedOption: FloatingDropdownOptionProps) => {
    selectedOption.id && onTimezoneSelected(selectedOption.id);
  };
  const shouldShowTimezoneDropdown = selectableTimezones.length > 1;
  return (
    <div data-hook={TimezoneSelectionDataHooks.TIMEZONE_SELECTION}>
      {shouldShowTimezoneDropdown ? (
        <FloatingDropdown
          className={st(classes.timezoneDropdown, { isMobile })}
          data-hook={TimezoneSelectionDataHooks.TIMEZONE_DROPDOWN}
          onChange={onChange}
          options={selectableTimezones.map((timezone, index) => ({
            id: timezone.value,
            value: timezone.formattedTimezone,
            isSelectable: true,
          }))}
          label={timezoneLabel}
          value={selectedTimezone.value}
          displayBlock={isMobile}
        />
      ) : (
        <Text
          data-hook={TimezoneSelectionDataHooks.TIMEZONE_TEXT}
          className={st(classes.timezoneText)}
        >
          {selectedTimezone.formattedTimezone}
        </Text>
      )}
    </div>
  );
};

export default TimezoneSelection;
